.contain-footer {
  background: var(--purple);
  width: 100%;
}

.logo-auta {
  width: 205px;
  height: 100px;
  margin-top: 50px;
}

.text-footer {
  color: var(--white);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 50px !important;
  margin-top: 40px !important;
  margin-right: 40px !important;
}

.contain-options-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 760px) {
  .contain-options-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .logo-auta {
    width: 143px;
    height: 70px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .text-footer {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    margin-right: 0px !important;
  }
}
