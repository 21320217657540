.header {
  border-radius: 0px 0px 24px 24px;
  border: 2px solid var(--stroke-purple, #7340d8);
  background: var(--purple, #864bfa);
}

.logo-auta-header {
  width: 72px;
  height: 24px;
}

.icon-header {
  padding: 0px !important;
  margin-right: 17px;
  margin-left: 7px;
}

.text-header {
  color: var(--white, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navbar-toggler {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  margin-top: 0;
}

.navbar-toggler:active {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.divider-header-menu {
  width: 90%;
  height: 2px;
  border-radius: 10px;
  background: var(--stroke-purple);
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
  .logo-auta-header {
    width: 48px;
    height: 16px;
    margin-bottom: 5px;
  }

  .navbar-brand {
    padding: 0px !important;
  }

  .header {
    /* padding: 0px !important; */
    padding-top: 15px !important;
    border-radius: 0px 0px 16px 16px;
  }

  .text-header {
    font-size: 16px;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.custom-dropdown-toggle::after {
  color: #fff !important;
  font-size: 20px;
  margin-left: 10px;
}

.custom-dropdown-toggle {
  background: transparent;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  /* width: 170px; */
  height: 30px;
  padding: 0px !important;
}

.icon-user {
  width: 18px;
  height: 18px;
}

.text-user-name {
  color: var(--purple);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-dropdown-toggle:hover {
  background-color: #d3d4d57a !important;
  border-color: transparent !important;
}

.dropdown-item:active {
  color: #864bfa;
}

.custom-menu-item:hover {
  background-color: transparent;
}

.custom-menu-item.disabled {
  color: #482481;
}

.dropdown-user {
  cursor: pointer;
  border-radius: 30px;
  background: var(--ultra-light-purple);
  padding: 4px;
}

.text-logout {
  color: var(--dark-purple);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropdown-logout {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  margin-bottom: -40px;
  height: 60px;
  border-radius: 16px;
  background: var(--light-purple);
}
