.contain-app {
  height: 100%;
}

.container-cards-home {
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 760px) {
  .container-cards-home {
    padding-left: 10px;
    padding-right: 10px;
  }
}
