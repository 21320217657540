.contain-header {
  /* padding-top: 50px; */
  background-image: url("../images/background-header.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 45vh;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-header-two {
  color: var(--white);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 50px !important;
  margin-top: 50px !important;
}

@media screen and (max-width: 760px) {
  .contain-header {
    background-image: url("../images/background-header-mobile.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% 25vh;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-header-two {
    font-size: 40px;
    margin-top: 20px !important;
  }
}
