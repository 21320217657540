.contain-login {
  /* padding-top: 50px; */
  height: 95vh;
  background-image: url("../images/background-login.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn-google-login {
  width: 303px;
  height: 36px;
  color: var(--white);
  font-family: "Mark Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 20px;
  border: 2px solid #c23c31;
  background: var(--google-red);
  margin-top: 20px;
}

.btn-google-login:hover {
  background: #c23c31;
}
