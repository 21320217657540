.card {
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white);
  padding: 10px;
  width: 530px;
  margin-top: 50px;
}

.container-title-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  background: var(--purple);
  padding: 10px;
}

.back-icon {
  cursor: pointer;
  height: 30px;
  position: absolute;
  top: 0;
  margin-top: 17px;
  left: 0;
  margin-left: 20px;
}

.title-card {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 600px) {
  .card {
    width: 400px;
  }
}
