.contain-section {
  margin-top: 100px;
}

.contain-text {
  border-radius: 10px;
  background: var(--purple);
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;
}

.text-one {
  color: var(--white);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-two {
  color: var(--purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px !important;
  margin-top: 10px !important;
}

.emoticon-one {
  width: 100px;
  height: 100px;
  margin-top: -60px;
  margin-right: -10px;
}

.emoticon-two {
  width: 100px;
  height: 100px;
  margin-top: -90px;
  margin-left: -25px;
}

.container-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card-benefits {
  width: 280px;
  height: 226px;
  border-radius: 16px 16px 28px 28px;
  border: 2px solid var(--light-purple);
  background: var(--white);
  cursor: pointer;
  padding: 20px;
  margin: 20px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 8px 0 0 #eee;
  -webkit-box-shadow: 0 8px 0 0 #eee;
}

.card-benefits:hover {
  box-shadow: 0 0 0 9px #eee;
  -webkit-box-shadow: 0 0 0 9px #eee;
  -moz-box-shadow: 0 0 0 9px #eee;
}

.btn-show-benefits {
  border-radius: 300px;
  border: 3.35px solid var(--stroke-purple);
  background: var(--purple);
  color: var(--white, #fff);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 15.633px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  height: 36px;
}

.title-body-benefits {
  color: var(--purple);
  font-family: "Mark Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px !important;
}

.text-body {
  color: var(--dark-purple);
  font-family: "Mark Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.divider-body {
  margin-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .contain-section {
    margin-top: 20px;
  }

  .contain-text {
    border-radius: 10px;
    background: var(--purple);
    padding-left: 8px;
    padding-right: 8px;
    z-index: 1;
  }

  .emoticon-one {
    width: 40px;
    height: 40px;
    margin-top: -40px;
    margin-right: -12px;
  }

  .emoticon-two {
    width: 40px;
    height: 40px;
    margin-top: -42px;
    margin-left: -18px;
  }

  .text-one {
    font-size: 30px;
  }

  .text-two {
    font-size: 30px;
  }
}
